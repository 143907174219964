.dashboard-right-sidebar{
    height: 100%;
}
.support-chat{
    &-wrapper{
        padding: 16px;
        background: #fff;
        border-radius: 8px;
        
    .block-center{
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    }
    &-item{
        font-size: 16px;
        font-weight: 500;
        border-radius: 16px 16px 16px 0px;
        background: #EAFFE0;
        padding: 8px 16px;
        max-width: 80%;

        &-wrapper{
            display: flex;
            flex-direction: column;
            gap: 10px;
            margin-bottom: 16px;

            .date{
                text-align: center;
            }
        }

        &.right{
            margin-left: auto;
            border-radius: 16px 16px 0px 16px;
        }

        .time{
            font-size: 12px;
            color: #7E7E7E;
            margin-top: 4px;
        }
    }
    &-content{
        height: 400px;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        gap: 32px;

        &::-webkit-scrollbar {
            width: 5px;
            opacity: 0;
            position: absolute;
        }
        &::-webkit-scrollbar-track {
            background: #f1f1f1;
            box-shadow: 0 0 2px rgba(0, 0, 0, .2) inset;
        }
        &::-webkit-scrollbar-thumb {
            background: #c1c1c1;
            border-radius: 4px;
            border: 1px solid #f1f1f1;
        }
        &::-webkit-scrollbar-thumb:hover {
            background: #000000;
        }
    }
}
.send-msg{
    display: flex;
    gap: 8px;
}
.chat-input{
    outline: none;
    border: none;
    width: 100%;
    border-radius: 8px;
    background: #F9F9F9;
    padding: 12px;
}

.chat-send-button{
    border-radius: 8px;
    background: var(--mainColor);
    height: 42px;
    width: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.chat-send-file{
    height: 42px;
    width: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    position: relative;
    background: rgba(51, 205, 85, 0.10);

    input{
        width: 100%;
        width: 100%;
        position: absolute;
        opacity: 0;
        top: 0;
        left: 0;
    }
}
.file-item{
    display: flex;
    gap: 10px;
    width: 100%;
    .title{
        font-size: 16px;
        font-weight: 600;
    }
    .size{
        color: #9D9D9D;
    }
    .file-icon{
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background: #442EC8;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
        color: #fff;
    }
}
.images-flex{
    display: flex;
    flex-wrap: wrap;
    gap: 4px;

    img{
        width: 60px;
        height: 60px;

        &:nth-child(1){
            width: 100%;
            height: auto;
            max-height: 240px;
            object-fit: contain;
        }
    }
}
.chat-file-input{
    outline: none;
    border: none;
    border-bottom: 2px solid var(--mainColor);
    height: 44px;
    width: 100%;
}
.chat-file-button{
    color: var(--mainColor);
    font-weight: 500;
    font-size: 16px;
    margin-top: 20px;
    cursor: pointer;
    text-align: right;
}
.images-bottom{
    margin-top: 20px;
}

@media(max-width: 776px){
    .support-chat-wrapper{
        padding: 8px;
    }
    .send-msg{
        gap: 4px;
    }
}
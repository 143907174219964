//assistant

.assistant{
    position: fixed;
    bottom: 20px;
    right: 50px;
    z-index: 10;
    cursor: pointer;

    &-img{
        width: 113px;
        height: 113px;
        object-fit: contain;
    }
    &-text{
        background: #fff;
        box-shadow: (0px 4px 10px rgba(0, 0, 0, 0.15));
        padding: 8px;
        border-radius: 10px;
        max-width: 160px;
        position: relative;

        &::before{
            content: "";
            position: absolute;
            background: #fff;
            height: 20px;
            width: 13px;
            bottom: -11px;
            left: 0;
            clip-path: polygon(0 0, 0% 100%, 100% 0);
        }
    }
}
.filter-price{
    margin-top: 30px;
    .noUi-horizontal{
        height: 10px;
    }
    .noUi-target{
        background-color: #f9f8f6;
        border-radius: 50px;
        border: none;
        width: 90%;
    }
    .noUi-horizontal .noUi-handle{
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 3px solid #fff;
        background: var(--mainColor);

        .noUi-touch-area{
            background: var(--mainColor);
            border-radius: 50%;
        }
    }
    .noUi-connect{
        background: var(--mainColor);
    }
    .noUi-handle:before, .noUi-handle:after{
        display: none;
    }
    .noUi-tooltip{
        background-color: var(--mainColor);
        border: none;
        white-space: nowrap;
        color: #fff;
        font-size: 13px;
        line-height: 1.333;
        padding: 3px 7px;
        border-radius: 3px;
    }

    .filter-price__title{
        margin-top: 20px;
    }
}

.checkout-slider{
    margin-top: 30px;
    position: relative;

    .slider-labels{
        position: absolute;
        top: 36px;
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    h3{
        margin-bottom: 35px;
        font-weight: 600;
        font-size: 18px;
    }
    .noUi-horizontal{
        height: 6px;
    }
    .noUi-target{
        background-color: #E4E4E6;
        border-radius: 50px;
        border: none;
        width: 90%;
    }
    .noUi-horizontal .noUi-handle{
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 3px solid #fff;
        background: var(--mainColor);

        .noUi-touch-area{
            background: var(--mainColor);
            border-radius: 50%;
        }
    }
    .noUi-connect{
        background: var(--mainColor);
    }
    .noUi-handle:before, .noUi-handle:after{
        display: none;
    }
    .noUi-tooltip{
        background-color: var(--mainColor);
        border: none;
        white-space: nowrap;
        color: #fff;
        font-size: 13px;
        line-height: 1.333;
        padding: 3px 7px;
        border-radius: 3px;
    }

    .filter-price__title{
        margin-top: 20px;
    }
}
/**=====================
     Tolltip scss
==========================**/
.tooltip {
    transition: opacity 0.15s linear;
    .tooltip-arrow {
        &::before {
            border-top-color: var(--mainColor);
        }
    }

    .tooltip-inner {
        font-weight: 500;
        background-color: var(--mainColor);
        color: $white;
    }
}

// Pagination

// scss-docs-start pagination-mixin
@mixin pagination-size($padding-y, $padding-x, $font-size, $border-radius) {
    .pagination{

  li {
    a {
        padding: $padding-y $padding-x;
        @include font-size($font-size);
      }
    @if $pagination-margin-start == (-$pagination-border-width) {
      &:first-child {
        a {
          @include border-start-radius($border-radius);
        }
      }

      &:last-child {
        a {
          @include border-end-radius($border-radius);
        }
      }
    } @else {
      //Add border-radius to all pageLinks in case they have left margin
      a{
        @include border-radius($border-radius);
      }
    }
  }
}
}
// scss-docs-end pagination-mixin

/**=====================
     Product Page scss
==========================**/
.pt-25 {
    padding-top: 25px;
}

.product-load-more {
    .col-grid-box {
        display: none;
    }
}

.product-title {
    margin-top: 20px;

    h4 {
        font-weight: 600;
        margin-bottom: calc(10px + (12 - 10) * ((100vw - 320px) / (1920 - 320)));
        letter-spacing: 0.4px;
        font-size: calc(15px + (16 - 15) * ((100vw - 320px) / (1920 - 320)));
    }
}

.vendor-box {
    padding: calc(12px + (33 - 12) * ((100vw - 320px) / (1920 - 320)));
    background-color: $light-gray;

    .verndor-contain {
        @include flex_wrap ($dis: flex, $wrap: wrap, $gap: 10px);
        padding: 7px calc(16px + (26 - 16) * ((100vw - 320px) / (1920 - 320)));
        background-color: $white;
        align-items: center;

        .vendor-image {
            @include pseudowh($width: 64px, $height: auto);

            img {
                @include pseudowh;
                object-fit: contain;
            }
        }
    }

    .vendor-list {
        margin-top: calc(13px + (24 - 13) * ((100vw - 320px) / (1920 - 320)));

        ul {
            @include flex_wrap ($dis: flex, $wrap: wrap, $gap: calc(9px + (18 - 9) * ((100vw - 320px) / (1920 - 320))));
            align-items: center;

            [dir="rtl"] & {
                padding-right: 0;
            }

            li {
                display: block;

                .address-contact {
                    @include flex_wrap ($dis: flex, $wrap: nowrap, $gap: 8px);

                    .feather {
                        @include pseudowh($width: 20px, $height: 20px);
                        color: var(--mainColor);
                    }

                    h5 {
                        font-weight: 600;

                        span {
                            font-weight: 400;
                            margin-left: 7px;
                        }
                    }
                }
            }
        }
    }

    .vendor-detail {
        margin: calc(13px + (28 - 13) * ((100vw - 320px) / (1920 - 320))) 0 0;
        line-height: 1.7;
        color: $content-color;
        padding-bottom: calc(10px + (18 - 10) * ((100vw - 320px) / (1920 - 320)));
        border-bottom: 1px solid $border-color;
    }
}

.hot-line-number {
    padding: calc(12px + (33 - 12) * ((100vw - 320px) / (1920 - 320)));
    background-color: $light-gray;

    h5 {
        font-weight: 600;
        margin-bottom: 4px;
    }

    h6 {
        color: $content-color;
        margin-bottom: 17px;
    }

    h2 {
        font-weight: 600;
        font-size: 24px;
    }
}

.product-category {
    gap: 25px;
    padding: calc(12px + (33 - 12) * ((100vw - 320px) / (1920 - 320)));
}

.product-section {
    .product-left-box {
        width: 435px;
        position: sticky;
        top: 0;
    }

    .left-slider-image {
        .slick-prev:before, .slick-next:before{
            color: var(--mainColor);
            font-size: 22px;
        }
        .sidebar-image {
            overflow: hidden;
            height: 100%;
            height: 94px;

            img {
                @include pseudowh($width: auto, $height: auto);
                cursor: pointer;
                object-fit: contain;
                height: 100%;
            }
        }
    }

    .right-box-contain {
        position: sticky;
        top: 10px;
        left: 0;

        @include mq-max(md) {
            text-align: center;
        }

        .offer-top {
            font-weight: 500;
            padding: calc(8px + (10 - 8) * ((100vw - 320px) / (1920 - 320))) calc(12px + (16 - 12) * ((100vw - 320px) / (1920 - 320)));
            background-color: rgba($danger-color, $alpha: .1);
            border-radius: 5px;
            color: $danger-color;
            display: inline-block;
            margin-bottom: 20px;
        }

        .name {
            font-weight: 700;
            margin-bottom: calc(10px + (15 - 10) * ((100vw - 320px) / (1920 - 320)));
        }

        .price-rating {
            @include flex_common ($dis: flex, $align: center, $justify: space-between);

            &-2 {
                @include mq-max(3xl) {
                    display: block;
                }

                .custom-rate {
                    @include mq-max(3xl) {
                        margin-top: 5px;
                    }
                }
            }

            @include mq-max(md) {
                display: block;
            }

            &-box {
                @include mq-max(3xl) {
                    @include flex_common ($dis: flex, $align: center, $justify: space-between);
                }

                @include mq-max(xs) {
                    display: block;
                }

                .custom-rate {
                    @include mq-max(3xl) {
                        margin-top: 0 !important;
                    }

                    @include mq-max(xs) {
                        margin-top: 10px !important;
                    }
                }
            }

            .custom-rate {
                @include mq-max(md) {
                    margin-top: 10px;
                    justify-content: center;
                }
            }

            .price {
                font-weight: 600;
                font-size: calc(19px + (22 - 19) * ((100vw - 320px) / (1920 - 320)));

                del {
                    font-weight: 400;
                    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
                    margin: 0 calc(0px + (2 - 0) * ((100vw - 320px) / (1920 - 320)));
                }

                span {
                    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
                    font-weight: 500;
                }
            }

            .custom-rate {
                ul {
                    li {
                        .feather {
                            @include pseudowh($width: 16px, $height: 16px);
                        }
                    }
                }

                .review {
                    font-size: 13px;
                    margin-left: 12px;

                    [dir="rtl"] & {
                        margin-left: unset;
                        margin-right: 12px;
                    }
                }
            }
        }

        .procuct-contain {
            border-bottom: 1px solid $border-color;
            padding-bottom: 16px;

            p {
                color: $content-color;
                line-height: 1.7;
                margin: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320))) 0 0;
                font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
                width: 80%;

                @include mq-max(md) {
                    width: 100%;
                }
            }
        }

        .product-packege {
            .select-packege {
                @include flex_wrap ($dis: flex, $wrap: wrap, $gap: calc(5px + (13 - 5) * ((100vw - 320px) / (1920 - 320))));

                @include mq-max(md) {
                    justify-content: center;
                }

                [dir="rtl"] & {
                    padding-right: 0;
                }

                li {
                    a {
                        padding: 6px 11px;
                        border: 1px solid $border-color;
                        border-radius: 4px;
                        display: block;
                        color: $content-color;
                        font-size: 14px;
                        transition: all 0.3s ease-in-out;

                        &.active {
                            border: 1px solid var(--mainColor);
                            background: var(--mainColor2);
                            color: $white;
                        }
                    }
                }
            }
        }

        .note-box {
            @include flex_wrap ($dis: flex, $wrap: nowrap, $gap: calc(7px + (15 - 7) * ((100vw - 320px) / (1920 - 320))));
            align-items: center;
            margin-top: 20px;

            @include mq-max(md) {
                justify-content: center;
            }

            .compare-button {
                background-color: #f8f8f8;
            }

            .cart-button {
                animation: shake 150ms 2 linear;
            }

            .product-qty {
                width: auto;
                margin-top: 0;

                @include mq-max(2xs) {
                    width: 160px;
                }

                .input-group {
                    background: linear-gradient(187.77deg, #fafafa 5.52%, #f8f8f8 94%);
                }
            }
        }

        .buy-now-button {
            margin-top: 20px;
            padding-bottom: 16px;
            border-bottom: 1px solid $border-color;

            button {
                background-color: #FF7272;
            }
        }

        .buy-box {
            @include flex_wrap ($dis: flex, $wrap: wrap, $gap: 11px);
            align-items: center;
            margin-top: 20px;
            border-bottom: 1px solid #ececec;
            padding-bottom: 16px;

            .team-box {
                padding: 0;
                margin: 0;
                display: flex;
                align-items: center;

                input {
                    margin-top: -7px;
                    display: block;
                }

                .form-check-label {
                    span {
                        display: flex;
                        align-items: center;

                        a {
                            text-decoration: underline;
                            margin-left: 3px;
                            color: $title-color;
                        }
                    }
                }
            }

            @include mq-max(md) {
                justify-content: center;
            }

            a {
                @include flex_wrap ($dis: flex, $wrap: wrap, $gap: calc(5px + (8 - 5) * ((100vw - 320px) / (1920 - 320))));
                align-items: center;
                color: #777;

                .feather {
                    @include pseudowh($width: 17px, $height: auto);
                }
            }
        }

        .pickup-box {
            padding-bottom: 20px;
            border-bottom: 1px solid $border-color;

            .product-info {
                margin-top: 20px;

                .product-info-list {
                    @include flex_wrap ($dis: flex, $wrap: wrap, $gap: calc(8px + (17 - 8) * ((100vw - 320px) / (1920 - 320))));
                    align-items: flex-start;
                    text-align: left;
                    display: inline-block;
                    column-count: 2;
                    background-color: #f9f9f9;
                    padding: 15px;
                    border-radius: 12px;
                    width: 80%;

                    @include mq-max(xs) {
                        column-count: 1;
                        padding: 15px;
                    }

                    &-2 {
                        display: flex;

                        @media (max-width: 1500px) {
                            width: 100%;
                        }

                        @media (max-width: 1450px) {
                            column-count: 1;
                            padding: 15px;
                        }

                        @include mq-max(xl) {
                            column-count: 2;
                            display: block;
                            padding-bottom: 4px;

                            li {
                                margin-bottom: 8px;
                            }
                        }

                        @include mq-max(xs) {
                            display: flex;
                            padding-bottom: 15px;

                            li {
                                margin-bottom: 0;
                            }
                        }
                    }

                    &-3 {
                        width: 50%;

                        li {
                            margin-bottom: 7px;

                            @include mq-max(2xs) {
                                margin-bottom: 0;
                            }
                        }

                        @include mq-max(2xl) {
                            width: 80%;
                        }

                        @include mq-max(md) {
                            width: 100%;
                        }

                        @include mq-max(2xs) {
                            display: flex;
                        }
                    }

                    li {
                        padding-left: 13px;
                        width: 100%;
                        font-size: calc(14px + (15 - 14) * ((100vw - 320px) / (1920 - 320)));
                        position: relative;
                        color: #777;

                        @include mq-max(sm) {
                            width: auto;
                            margin-left: 18px;
                        }

                        @include mq-max(xs) {
                            margin-left: unset;
                            width: 100%;
                            padding-left: unset;

                            &::after {
                                content: none !important;
                            }
                        }

                        &::after {
                            @include pos;
                            @include center(vertical);
                            @include pseudowh($width: 5px, $height: 5px);
                            left: 0;
                            background-color: $content-color;
                            border-radius: 100%;
                        }

                        a {
                            margin-left: 5px;
                            color: $title-color;
                            font-weight: 500;

                            +a {
                                margin-left: 0;
                            }
                        }
                    }
                }
            }

            .pickup-icon {
                i {
                    font-size: 20px;
                    color: var(--mainColor);
                }
            }

            .pickup-detail {
                h4 {
                    width: 90%;
                    font-weight: 400;
                    margin-bottom: 5px;
                    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
                    line-height: 1.6;

                    @include mq-max(md) {
                        width: 100%;
                    }
                }

                h6 {
                    margin-top: 4px;
                }

                a {
                    margin-top: 0;
                    color: var(--mainColor);
                    display: inline-block;
                }
            }
        }

        .paymnet-option {
            ul {
                align-items: center;
                @include flex_wrap ($dis: flex, $wrap: wrap, $gap: calc(3px + (8 - 3) * ((100vw - 320px) / (1920 - 320))));

                @include mq-max(md) {
                    justify-content: center;
                }

                [dir="rtl"] & {
                    padding-right: 0;
                }
            }
        }

        .share-option {
            padding-bottom: 20px;

            ul {
                @include flex_wrap ($dis: flex, $wrap: wrap, $gap: 20px);

                li {
                    display: block;

                    a {
                        display: block;
                        text-align: center;
                        font-size: 17px;
                        color: $content-color;
                    }
                }
            }
        }
    }

    .product-main {
        .slider-image {
            width: 435px;
            height: 435px;
            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }

    .right-sidebar-box {
        /*position: sticky;
        top: 10px;*/

        .product-right-sidebar {
            li {
                +li {
                    margin-top: 0;

                    &::after {
                        top: -14px;
                        width: calc(100% - 103px);
                    }
                }
            }
        }
    }
}

.product-wrapper{
    .product-flex{
        display: flex;
        flex-wrap: wrap;
    }
    .product-label{
        display: flex;
        margin: 3px;
        input{
            position: absolute;
            pointer-events: none;
            opacity: 0;
        }

        span{
            cursor: pointer;
            border: 2px solid #ececec;
            border-radius: 10px;
            display: block;
            color: #4a5568;
            font-size: 14px;
            transition: all 0.3s ease-in-out;

            img{
                width: 44px;
                height: 44px;
                object-fit: contain;
                border-radius: 10px;
            }
        }
        .span-pad{
            padding: 6px 16px;
            border-radius: 23px;
        }
    }
    .product-flex input:not(:checked):not(:disabled) ~ span:hover {
        background: #e0e0e0;
        color: #3d464d;
    }
    .product-flex input:checked ~ span {
        cursor: default;
        border: 2px solid var(--mainColor);
        -webkit-transition-duration: 0.08s, 0.08s;
                transition-duration: 0.08s, 0.08s;
    }
    .product-flex input:disabled ~ span {
        cursor: default;
        opacity: 0.5;
    }
}

.related-product-2 {
    .related-product {
        .product-title-2 {
            margin-top: 0;

            h4 {
                font-size: calc(21px + (25 - 21) * ((100vw - 320px) / (1920 - 320)));
                font-weight: 600;
                margin-bottom: calc(16px + (21 - 16) * ((100vw - 320px) / (1920 - 320)));
            }
        }
    }

    .related-box {
        display: flex;
        align-items: flex-start;
        flex-wrap: nowrap;
        gap: 15px;

        @media (max-width: 1577px) {
            flex-wrap: wrap;
        }

        .related-image {
            touch-action: pan-x;
            overflow: auto;
            padding-bottom: 13px;

            >ul {
                display: flex;
                align-items: flex-start;
                flex-wrap: nowrap;
                gap: calc(25px + (35 - 25) * ((100vw - 320px) / (1920 - 320)));

                >li {
                    width: 225px;
                    min-width: 225px;
                    position: relative;

                    +li {
                        &::before {
                            @include pos($pos: absolute, $content: "\2b");
                            @include center(vertical);
                            left: calc(-18px + (-26 - -18) * ((100vw - 320px) / (1920 - 320)));
                            font-size: calc(14px + (20 - 14) * ((100vw - 320px) / (1920 - 320)));
                            @include font;
                            color: $content-color;
                        }
                    }

                    a {
                        display: block;
                        overflow: hidden;

                        .image-box {
                            background-color: $light-gray;
                            width: calc(136px + (170 - 136) * ((100vw - 320px) / (1920 - 320)));
                            height: calc(136px + (170 - 136) * ((100vw - 320px) / (1920 - 320)));
                            padding: 10px;
                            @include flex_common;
                            position: relative;
                            border-radius: calc(6px + (9 - 6) * ((100vw - 320px) / (1920 - 320)));

                            .form-check {
                                position: absolute;
                                top: 8px;
                                left: 8px;
                                padding: 0;

                                .checkbox_animated {
                                    &::after {
                                        border: 1px solid #4a5567;
                                        border-radius: 3px;
                                    }
                                }
                            }
                        }

                        .related-content {
                            margin-top: 12px;
                            padding: 0 calc(4px + (12 - 4) * ((100vw - 320px) / (1920 - 320)));

                            h5 {
                                font-size: 15px;
                                line-height: 1.2;
                                color: $title-color;
                                -webkit-line-clamp: 1;
                                -webkit-box-orient: vertical;
                                display: -webkit-box;
                                overflow: hidden;
                            }

                            h6 {
                                margin-top: 5px;
                                color: var(--mainColor);

                                del {
                                    color: $content-color;
                                    margin-left: 4px;
                                    font-size: 13px;
                                }
                            }
                        }
                    }
                }
            }
        }

        .budle-list {
            gap: 15px;
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            >ul {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                gap: 15px;

                >li {
                    width: 100%;
                    display: block;

                    .form-check {
                        display: flex;
                        padding: 0;
                        margin-bottom: 0;
                        align-items: center;

                        .checkbox_animated {
                            margin-top: -4px;
                        }

                        .form-check-label {
                            span {
                                font-size: calc(15px + (17 - 15) * ((100vw - 320px) / (1920 - 320)));
                                color: $content-color;

                                span {
                                    color: $title-color;
                                    font-weight: 600;
                                }
                            }
                        }
                    }

                    &.contant {
                        margin-top: calc(0px + (15 - 0) * ((100vw - 320px) / (1920 - 320)));

                        h5 {
                            font-size: 17px;
                            font-weight: 600;
                            color: #4a5568;
                        }

                        h4 {
                            margin-top: 8px;
                            font-weight: 700;

                            del {
                                font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
                                margin-left: 5px;
                                font-weight: 400;
                            }
                        }
                    }
                }
            }
        }
    }
}
.configuration-price{
    //margin-top: 20px;

    .configuration-price-item{
        width: 100%;
        position: relative;
        border-radius: 7px;
        background: #FAFAFA;
        padding: 10px;
        text-align: center;
        color: #4A5568;
        cursor: pointer;
        line-height: 1.1;
        border: 2px solid #FAFAFA;
        transition: .3s;

        &:hover{
            border: 2px solid var(--mainColor);
        }

        &.active{
            border: 2px solid var(--mainColor);
        }

    }
    &:not(:last-child){
        margin-bottom: 10px;
    }
    .configuration-price-title{
        font-weight: 600;
        color: #222;
        font-size: 16px;
    }

    
}

.product-droupdown{
    position: absolute;
    right: 20px;
    top: 34%;
    
    i{
        color: #4A5568;
        font-size: 18px;
    }
}
.dropdown-modal{
    .modal-content{
        background: #fff;
        border-radius: 8px;
        border: none;
        color: #4A5568;
    }
    .modal-body{
        padding: 0 20px 20px;
    }
}
@media(max-width: 767px){
    .product-droupdown{
        .dropdown-menu{
            width: 300px;
        }
    }
}
.sticky-bottom-cart {
    position: fixed;
    bottom: -160px;
    width: 100%;
    z-index: 9;
    background-color: $white;
    padding: calc(11px + (6 - 11) * ((100vw - 320px) / (1920 - 320))) 0;
    box-shadow: 0 0 3px 1px rgba($title-color, 0.06);
    transition: all 0.3s ease;

    .cart-content {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include mq-max(sm) {
            display: block;
        }
    }

    .product-image {
        display: flex;
        align-items: center;

        @include mq-max(sm) {
            display: none;
        }

        img {
            width: 60px;
            height: 70px;
            object-fit: cover;
            object-position: top;
        }

        .content {
            margin-left: 12px;
            color: $title-color;
            margin-top: 0;

            [dir="rtl"] & {
                margin-left: 0;
                margin-right: 12px;
            }

            @include mq-max(lg) {
                display: none;
            }

            @include mq-max(xs) {
                display: block;
            }

            h5,
            h6 {
                margin-bottom: 0;
            }

            h5 {
                font-size: 16px;
                font-weight: 500;
                text-transform: capitalize;
            }

            h6 {
                font-size: 15px;
                margin-top: 6px;

                del {
                    margin-left: 7px;
                    color: $content-color;

                    [dir="rtl"] & {
                        margin-left: unset;
                        margin-right: 7px;
                    }
                }

                span {
                    color: var(--mainColor);
                    margin-left: 5px;

                    [dir="rtl"] & {
                        margin-left: 0;
                        margin-right: 5px;
                    }
                }
            }
        }
    }

    .selection-section {
        display: flex;
        align-items: center;
        margin: 0 25px 0 auto;

        @include mq-max(sm) {
            display: none;
        }

        .form-control {
            background-color: #f8f8f8;
            border: 1px solid #f8f8f8;
            text-transform: capitalize;

            &:focus {
                box-shadow: none;
            }
        }

        .product-qty {
            height: 51px;

            .input-group {
                button {
                    height: 100%;
                }
            }
        }

        .form-group {
            width: 300px;
            margin-left: 10px;
            margin-right: 10px;

            @include mq-max(xl) {
                width: 190px;
            }

            @include mq-max(md) {
                margin-right: 0;
                width: unset;
            }
        }
    }

    .add-btn {
        @include mq-max(sm) {
            @include flex_common;
        }

        a {
            padding: 12px 40px;

            @include mq-max(sm) {
                width: 100%;
                @include flex_common;
            }

            &.wishlist-btn {
                display: none;
                background-color: #f8f8f8;
                color: $title-color;

                &:hover {
                    background-color: var(--mainColor);
                    border-color: var(--mainColor);
                    color: $white;
                }

                @include mq-max(sm) {
                    display: flex;
                }
            }

            +a {
                @include mq-max(sm) {
                    margin-left: 8px;

                    [dir="rtl"] & {
                        margin-right: 8px;
                        margin-left: unset;
                    }
                }
            }

            i {
                margin-right: 7px;

                [dir="rtl"] & {
                    margin-right: unset;
                    margin-left: 7px;
                }
            }
        }
    }
}

.stickyCart {
    padding-bottom: 94px;

    @include mq-max(sm) {
        padding-bottom: 0;
    }

    .sticky-bottom-cart {
        bottom: 0;
        transition: all 0.3s ease;
    }

    .recently-purchase {
        &.show {
            bottom: 110px;
        }
    }

    .tap-top {
        bottom: 110px;
    }
}
.admin-top-header{
    background: #F3F3F3;
}

.header-tabs{
    display: flex;
    gap: 5px;
    align-self: end;
    &-item{
        font-size: 18px;
        padding: 10px 15px;
        background: #EAEDEF;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        box-shadow: 0px -4px 10px 0px rgba(0, 0, 0, 0.10);
        cursor: pointer;
        transition: .3s;

        &:hover{
            background: #fff;
        }

        &.active{
            background: #fff;
        }
    }
}
.top-header-wrapper{
    display: flex;
    justify-content: space-between;
}
.top-header-flex{
    display: flex;
    gap: 15px;
    align-items: center;
    position: relative;
    z-index: 1;
    padding: 8px 0;
    i{
        font-size: 24px;
    }
}
.header-line{
    width: 1px;
    height: 53px;
    background: #9CA5AF;
}

.header-btn{
    cursor: pointer;
    padding: 0 4px;
    color: #9CA5AF;
    transition: .3s;

    &:hover{
        color: #FF4242;
    }

    &.active{
        color: #FF4242;
    }
}
.fs-18{
    font-size: 18px;
}
.btn-question{
    color: #FF4242;
    cursor: pointer;
}
.admin-ckheckout{
    .form-switch{
        .form-check-input{
            width: 45px;
            height: 20px;
            border-radius: 30px;

            &.active {
                background-color: #FF4242;
                border-color: #FF4242;
                background-position: right center;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
            }

            &:checked{
                background-color: #FF4242;
                border-color: #FF4242;
            }
        }
    }
}